* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /*outline: 1px dotted black;*/
}

html, body {
  height: 100%;
  font-family: 'Baloo Thambi 2', cursive, 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #556b2f !important;
  font-size: 30px;
}

.container {
  max-width: 1366px;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 1366px) {
  .container {
    padding: 0 1.3rem;
  }
}
